<template>
  <div class="columns is-multiline is-flex-mobile infosection">
    <div class="column is-12-mobile  is-hidden-tablet is-hidden-desktop">
      <figure class="image">
        <img src="@/assets/img/info/image7.png" class="img-info" />
      </figure>
    </div>
    <div class="column is-12-mobile is-7 info-text">
      <ul>
        <li class="list">One free Candlelight Dinner or a 25-minute couple’s massage</li>
        <li class="list">Character Breakfast *only available in Punta Cana</li>
        <li class="list">One In-suite bottle of Sparkling Wine upon arrival</li>
        <li class="list">Premium Pillow Menu• Premium Aromatherapy Menu</li>
      </ul>
    </div>
    <div class="column is-12-mobile is-5   is-hidden-mobile">
      <figure class="image">
        <img src="@/assets/img/info/image7.png" class="ml-3 img-info" />
      </figure>
    </div>
    <div class="column is-12-mobile is-6">
      <figure class="image">
        <img src="@/assets/img/info/image6.png" class=" img-info" />
      </figure>
    </div>
    <div class="column is-12-mobile is-6 info-text">
      <ul >
        <li class="list">Nightly Turndown Service</li>
        <li class="list">24-hour Room Service</li>
        <li class="list">Early Check in and Late Check out upon availability</li>
        <li class="list">20% discount on Spa Treatments</li>
        <li class="list">20% discount on Resort Services</li>
        <li class="list">20% discount on Tours</li>
        <li class="list">20% discount on Resort Gift Shops</li>
      </ul>
    </div>
  </div>
</template>

<script setup>

</script>

<style>
.list{padding-bottom: 2% !important;}
@media only screen and (min-width: 1024px) {
  .img-info {

    width: auto !important;
  }

  .infosection{

    margin-left: 10% !important;
  }
  .info-text {
    text-align: justify;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .infosection{

    margin-left: 5% !important;
  }

  .info-text {
    color: var(--blackbase, #000);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}


@media only screen and (max-width: 768px) {
  .img-info {
    height: 220px !important;
    width: 285px !important;
    margin-left: 10%;
  }
  .info-text {
    color: var(--blackbase, #000);
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 4%;
  }
}
</style>