<template>
  <div class="container">
    <div class="columns">
      <div class="column">
        <figure class="image">
          <img
            src="@/assets/img/logos/img-logos.png"
            class="logos"
            style="height: auto; width: 100%"
          />
        </figure>
      </div>
      <!-- <div class="is-divider-vertical is-hidden-mobile"></div>
      <div class="column">
        <figure class="image">
          <img
            src="@/assets/img/logos/Frame284.svg"
            class="logos"
            style="height: 26px; width: 74px"
          />
        </figure>
      </div>
      <div class="column">
        <figure class="image">
          <img
            src="@/assets/img/logos/Frame285.svg"
            class="logos"
            style="height: 26px; width: 74px"
          />
        </figure>
      </div>
      <div class="column">
        <figure class="image">
          <img
            src="@/assets/img/logos/Frame286.svg"
            class="logos"
            style="height: 26px; width: 74px"
          />
        </figure>
      </div>
      <div class="column">
        <figure class="image">
          <img
            src="@/assets/img/logos/Frame287.svg"
            class="logos"
            style="height: 26px; width: 74px"
          />
        </figure>
      </div>
      <div class="column">
        <figure class="image">
          <img
            src="@/assets/img/logos/Frame288.svg"
            class="logos"
            style="height: 26px; width: 74px"
          />
        </figure>
      </div>
      <div class="column">
        <figure class="image">
          <img
            src="https://bulma.io/images/placeholders/256x256.png"
            class="logos"
            style="height: 26px; width: 74px"
          />
        </figure>
      </div> -->
    </div>
  </div>
</template>

<script setup>
</script>

<style>
.logos-section {
  background-image: url("../assets/img/logos/fondo_marcas.png");
  height: 50%;
}


@media only screen and (min-width: 1024px) {
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
}

@media only screen and (max-width: 768px) {
}
</style>