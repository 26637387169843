<template>
  <div class="container">
    <!-- Cards -->
    <div class="columns is-multiline is-centered">
      <div class="column is-12">
        <CardCarrusel
          v-if="
            form.url == 'interval' || form.url == 'rci' || form.url == '7across'
          "
          @open="open"
          img="external.png"
          :buttom="false"
          :title="`Dear ${form.url.toUpperCase()} Member :`"
          content="Your dream getaway awaits you at our luxury resort. Not only will you enjoy exclusive amenities designed for our Exotic Travelers Members. Also As part of our exclusive services we will introduce to you our Personal Concierge Program. "
        />
        <!-- carrucel -->
        <Carousel @slide-end="handleSlideStart" v-else>
          <Slide v-for="slide in slides" :key="slide.id">
            <div class="carousel__item">
              <CardCarrusel
                @open="open"
                :img="slide.img"
                :buttom="slide.buttom"
                :title="slide.title"
                :content="slide.content"
              />
            </div>
          </Slide>
          <template #addons>
            <Navigation />
            <Pagination />
          </template>
        </Carousel>
      </div>
      <!-- cards -->
      <div class="column is-12" v-if="slide == 1">
        <div class="columns is-multiline card-level-s">
          <div
            class="column is-one-fifth-desktop is-4-tablet"
            v-for="card in cards"
            :key="card.id"
          >
            <CardLevel
              :img="card.img"
              :title="card.title"
              :content="card.content"
            ></CardLevel>
          </div>
        </div>
      </div>
      <!-- info -->
      <div class="column is-12" v-if="slide == 2">
        <InfoSection></InfoSection>
      </div>
      <!-- form -->
      <div class="column is-12 form">
        <FormComponent
          :form="form.form"
          :img="form.img"
          :title="form.url"
        ></FormComponent>
      </div>
      <!-- flogos-->
      <div class="column is-12">
        <div class="is-divider divider"></div>
        <LogoComponent class="logos-section"></LogoComponent>
        <div class="is-divider divider"></div>
      </div>
    </div>
    <Modal
      v-model:visible="isVisible"
      title="Member Referral Program"
      width="65%"
    >
      <div class="modal-info">
        <ol>
          <li>
            Participants must be sponsored by an Exotic Travelers Member and
            must travel under the referral program with a minimum of 4-night
            stay. Rewards are not earned under a Guest of Member reservation.
            This is a one-time limited offer per referral.
          </li>
          <li>
            Married and cohabitating (min 24 months) participants must be
            between 30 years of age and 70 years of age, gainfully employed or
            retired and with at least one (1) major credit card and one (1)
            valid ID. (No Debit Cards, Check Cards, Company Cards or Discover
            Cards). The credit card and ID will be registered upon check in.
            Credit Card may be used for any incidentals purchased during their
            stay.
          </li>
          <li>
            Participants must travel together and attend the pre-scheduled
            120-minute Membership Presentation together with their partner at
            their specified time on the designated date.
          </li>
          <li>
            Participants may not be an owner, co-owner or a beneficiary of a
            current Exotic Travelers Membership. Direct family members of
            Exotic Travelers Members are not eligible for this promotion.
          </li>
          <li>
            The participant(s) may not have attended an Exotic Travelers
            membership presentation in the last 12 months or have travelled with
            any Exotic Travelers referral program in the past. Guests can
            register only once through the Rewards/Referral Program.
          </li>
          <li>
            All reservations made under the Member Referral Program require a
            $500 non-refundable deposit and must be paid in full no later than
            30 days prior to the arrival date of the participant. The cost of
            the reservation may not be absorbed by the member or third party.
          </li>
          <li>
            If traveling with other participants under the member Referral
            program, each guest is required to attend the 120 minute Membership
            Presentation separately and at their scheduled appointment time.
            This promotion is not valid for groups or weddings (a group is 3 or
            more rooms traveling together).
          </li>
          <li>
            The Member Referral Promotion as well as Member Night Redemption are
            not combinable with any other promotion or vacation offering of
            Exotic Travelers, Hotels and Resorts or any another
            third-party company. Crown and Monarch reservations are not
            applicable.
          </li>
          <li>
            Member Rewards: $400 Rewards Credit become applicable to the Member
            once the qualified referred participant makes a deposit for any new
            REWARDS reservation. Members may apply Rewards Credit to any level
            of reservation. Rewards Credit Letters must be applied at the time
            of booking for the member’s accommodations. These coupons have no
            cash value. Any credit letter presented at the time of booking will
            be redeemed in full. No reimbursements of any amount.
          </li>
          <li>
            Member Rewards: Rewards Credit for Membership purchased by the
            referred guest become applicable to the Member once their
            prospective participants purchase a membership. Rewards Credit
            amount is based on level of membership purchased.
          </li>
          <li>
            The Member Referral Program promotion may change or be removed based
            on seasonality and/or availability based upon the discretion
            Exotic Travelers without any prior or written consent.
          </li>
          <li>
            Failure to comply with all the above terms and conditions will
            result in the participant(s) being charged the public “rack” rate
            for their stay when checking out of the hotel. For more information
            about our Member Referral Program or to submit a Member Referral
            request please contact us directly using our Toll Free US & CAN: 855 282 0620 - MEX: 800 649 0826
            or by email to rewardsprograms@exotictravelers.com We look
            forward to assisting you in your next vacation of a lifetime.
          </li>
        </ol>
      </div>
    </Modal>
  </div>
</template>

<script setup>
import "vue3-carousel/dist/carousel.css";
import { ref, onBeforeMount } from "vue";
import { Carousel, Slide, Navigation, Pagination } from "vue3-carousel";
import CardCarrusel from "@/components/CardCarrusel.vue";
import CardLevel from "@/components/CardLevel";
import InfoSection from "@/components/InfoSection.vue";
import { Modal } from "usemodal-vue3";
import FormComponent from "@/components/FormComponent.vue";
import LogoComponent from "@/components/LogoComponent.vue";
import { data, defauldTheme } from "@/mirage/data";
let isVisible = ref(false);
let slide = ref(0);
let rute = ref(defauldTheme);
let form = ref({ form: "", img: "placeholder.png", url: "", title: "" });
const open = () => {
  isVisible.value = true;
};
const cards = [
  {
    id: "1",
    title: "Diamond Elite",
    img: "image1.png",
    content:
      "Earn $600 Rewards Credit when your referral purchases a Diamond Elite MemberShip",
  },
  {
    id: "2",
    title: "Diamond ",
    img: "image2.png",
    content:
      "Earn $600 Rewards Credit when your referral purchases a Diamond MemberShip",
  },
  {
    id: "3",
    title: "Emerald",
    img: "image3.png",
    content:
      "Earn $500 Rewards Credit when your referral purchases a Esmerald MemberShip",
  },
  {
    id: "4",
    title: "Ruby",
    img: "image4.png",
    content:
      "Earn $400 Rewards Credit when your referral purchases a Ruby MemberShip",
  },
  {
    id: "5",
    title: "Sapphire",
    img: "image5.png",
    content:
      "Earn $400 Rewards Credit when your referral purchases a Sapphire MemberShip",
  },
];
const slides = [
  {
    id: "1",
    img: "slider1.png",
    buttom: false,
    title: "REWARDS PROGRAM",
    content:
      "Exotic Travelers Members now have more ways to earn REWARDS! Earn cash to spend on future accommodations just for booking your referrals and even more cash when your referrals decide to join the Exotic Travel Club.",
  },
  {
    id: "2",
    img: "slider2.png",
    buttom: false,
    title: "BENEFITS FOR OUR MEMBERS",
    content: `
    <li>Earn $400 Rewards Credit to spend on accommodations just for booking referrals.</li>
<li>Earn *up to $600 Rewards Credit based on the level of membership purchased by the referrals. Break down below.</li>
    <li>Plus, your referral will also receive $200 Rewards Credit upon becoming new members.</li>
<li>Credit may be applied to a reservation of any level.</li>
    `,
  },
  {
    id: "3",
    img: "slider3.png",
    buttom: false,
    title: "BENEFITS FOR YOUR REFERRALS",
    content: `Through our Member Referral Program, invited guests are extended a one-time offer to experience what is to travel as a member, to enjoy special discounts and some benefits only available to Exotic Travelers Members.`,
  },
  {
    id: "4",
    img: "slider4.png",
    buttom: true,
    title: "QUALIFICATIONS",
    content: `To participate in the Member Referral Program, Member’s families, friends, and acquaintances must comply with all the following Terms & Conditions:`,
  },
];

const handleSlideStart = (data) => {
  slide.value = data.currentSlideIndex;
  console.log("slide-start", data.currentSlideIndex);
};
onBeforeMount(() => {

  /* el mirage solo funciona en dev, al momento de llegar aproduccion mara error por eso se busca desde la data */
  if (process.env.NODE_ENV == "production") {
    /* esta seccion formatea la url oara sollo obtener el nombre del subdominio */
    rute.value = window.location.origin;
    if(!rute.value.includes("https://")){
      rute.value = rute.value.replace("http://", "");
    }else{
      rute.value = rute.value.replace("https://", "");
    }

    
    rute.value = rute.value.split(".")[0];
/* aqui hace la busqueda del subdominio para traer el tema y la info requqerida */
    form.value = data.find((x) =>
      x.url.toUpperCase().includes(rute.value.toUpperCase())
    );
  } else {
    fetch(`api/level/${rute.value}`)
      .then((res) => res.json())
      .then((json) => {
        form.value = json;
        console.log(json);
      });
  }
});
</script>

<style>
.carousel__item {
  width: 100%;
}

.modal-info {
  height: fit-content;
  width: auto;
  padding: 1%;
}
.form{
  margin-bottom: 5%;
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .card-level-s {
    margin-left: 2% !important;
  }
  .carousel__item {
  }
}
</style>