/* el json esta formado de la siguiete manera
    {
        url:string,
        form:"string"
        img:"string"
        title:string
    }

    url: la url es el nombre subdominio 
    por ejemplo http://nonpresidential.exotictravelers.com/
    solo se agrega el nopresidential el cual es el nombre del sundominio si contar el resto de la url

    form: aqui va el url del formulario de huspod este se renderisa usando un iframe

    img: esta se utiliza en el componente FormCompnent esta es la imagen que acompaña al texto, por lo general se usa la joya

    title: en esta propiedad va el nombre de la hoja  de estilos sin extencions
*/
export const data = [
    {
        url: "Diamond",
        form: "https://share-eu1.hsforms.com/17WZKh1pUTVC1HslNAU_WpA2b98wm",
        img: "image2.png",
        title: "Diamond"
    },
    {
        url: "DiamondElite",
        form: "https://share-eu1.hsforms.com/1KX0GTz7iSduiccQyQjELGQ2b98wm",
        img: "image1.png",
        title: "DiamondElite"
    },
    {
        url: "Emerald",
        form: "https://share-eu1.hsforms.com/1jPBId8MTSpuMeTxB3pLkbA2b98wm",
        img: "image3.png",
        title: "Esmerald"
    },
    {
        url: "interval",
        form: "	https://share-eu1.hsforms.com/1Teh1TOtkQ0aOBLfRN9E1iA2b98wm",
        img: "externalInfo.png",
        title: "External1"
    },
    {
        url: "rci",
        form: "https://share-eu1.hsforms.com/1OY0GsWQRRsynT8MOc09cGg2b98wm",
        img: "externalInfo.png",
        title: "External2"
    },
    {
        url: "7across",
        form: "https://share-eu1.hsforms.com/1RNa8d7d1Q7ui2uqsYN7Kdg2b98wm",
        img: "externalInfo.png",
        title: "External3"
    },
    {
        url: "Nonpresidential",
        form: "https://share-eu1.hsforms.com/1luWpUxLDRrKi4plVIeQKxg2b98wm",
        img: "nickelodeon2.png",
        title: "Nonpresidential"
    },
    {
        url: "Ruby",
        form: "https://share-eu1.hsforms.com/1QQnJj9-fRKWWU907hy_Klw2b98wm",
        img: "image4.png",
        title: "Ruby"
    },
    {
        url: "Sapphire",
        form: "https://share-eu1.hsforms.com/1xUUWPRj1TriJBqbZtmtCSA2b98wm",
        img: "image5.png",
        title: "Sapphire"
    },
    {
        url: "SapphireElite",
        form: "https://share-eu1.hsforms.com/18zzMm5jKSfay58QNUTwmFw2b98wm",
        img: "image5.png",
        title: "SapphireElite"
    },
]
/* aqui se cambie el tema en la version de dev */
export const defauldTheme = "Ruby"