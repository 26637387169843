<template>
  <main-layout>
    <HomePage></HomePage>
  </main-layout>
</template>

<script setup>
import HomePage from "@/page/HomePage.vue";
import MainLayout from "./components/MainLayout.vue";
import { data,defauldTheme } from "./mirage/data";
import { ref, onBeforeMount } from "vue";
let rute = ref(defauldTheme);
let form = ref({ form: "", img: "placeholder.png", url: "", title: "" });

onBeforeMount(async () => {
  if (process.env.NODE_ENV == "production") {
    rute.value = window.location.origin;
    if(!rute.value.includes("https://")){
      rute.value = rute.value.replace("http://", "");
    }else{
      rute.value = rute.value.replace("https://", "");
    }

    rute.value = rute.value.split(".")[0];
    console.log(rute.value);
    console.log(process.env.NODE_ENV);
  } else {
    console.log(process.env.NODE_ENV);

    await fetch(`api/level/${rute.value}`)
      .then((res) => res.json())
      .then((json) => {
        form.value = json;
        console.log(json);
      });
  }

  form.value = data.find((x) =>
    x.url.toUpperCase().includes(rute.value.toUpperCase())
  );
  import(`./assets/css/${form.value.title}.css`);
});
</script>

<style>
#app {
  margin-top: 100px;
}
</style>
