<template>
  <div>
    <div class="columns is-multiline is-flex-mobile">
      <div class="column is-7-tablet is-8-desktop is-12-mobile bt-form" style="text-align: center">
        <h3 class="title title-form" v-if="props.title != 'interval' && props.title != 'rci'&&props.title !='7across'">
          Your personalized adventure is just one step away!
        </h3>

        <h3 class="title title-form" v-else >
          What we offer through our Personal Concierge Program?
        </h3>

        <p class="text-form no-external" v-if="props.title != 'interval' && props.title != 'rci'&&props.title !='7across'">
          The pre-arrival form is the path to an unforgettable stay. Don't wait any longer and let us pamper you as you deserve. 
          <br>
          <br>
          Delay no more to personalize your upcoming trip; submit your preferences with a minimum of 7 days' notice to avoid receiving standard amenities.
        </p>
         
        <p class="text-form external-text" v-else>
         <ul>
          <li>Dinner reservations</li>
          <li>SPA reservations</li>
          <li>Special promotions</li>
          <li>In-suite special amenities arrangements</li>
          <li>Culinary experience activities</li>
          <li>Hotel general information. </li>
        </ul>
        </p>
        <br>
        <p class=" external-des" v-if="props.title == 'interval' || props.title == 'rci'||props.title =='7across'">Have a great vacations with us!</p>
        <br />
        <button class="button button-carrusel bt-form" @click="open">
          Share my information now
        </button>
      </div>
      <div class="column is-4-desktop is-12-mobile">
        <figure class="image img-form">
          <img
            :src="require(`@/assets/img/form/${props.img}`)"
          />
        </figure>
      </div>
    </div>

    <Modal
      v-model:visible="isVisible"
      title=""
      :width=" modalWhidth()"
    >
      <div>
        <iframe
          class="responsive-iframe"
          :src="props.form"
          frameborder="0"
        ></iframe>
      </div>
    </Modal>
  </div>
</template>
  
  <script setup>
import { Modal } from "usemodal-vue3";
import { ref, defineProps } from "vue";
let screen  = window.innerWidth;

const modalWhidth = ()=>{
  console.log(screen);
  console.log("id");
if(screen<=768) {
  console.log("else");
  return 'auto'
}
return '744px'
}
const open = () => {
  console.log("modal componente");

  isVisible.value = true;
};

let isVisible = ref(false);
const props = defineProps({
  form: String,
  img: String,
  title: String,
});
</script>
<style >
.responsive-iframe {
  width: 100%;
  height: 100%;
  min-height: 500px;
}
.bt-form {
  margin-top: 3%;
}

@media only screen and (min-width: 1024px) {
  .text-form {

    font-family: Montserrat;
    font-size: 20px;
    font-weight: 200 !important;
    font-style: normal;
    line-height: normal;
    text-align: justify !important;
  }

  .external-text {
    margin-left: 2%;
  }
  .img-form {
    display: block;
    margin-right: auto;
    margin-left: auto;
    margin-top: 10%;
  }
  .external-des {

    font-family: Montserrat;
    font-size: 20px;
    font-weight: 200 !important;
    font-style: normal;
    line-height: normal;
    text-align: justify !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .img-form {
    display: block;
    margin-right: auto;
    margin-left: auto;
    margin-top: 10%;
  }
  .title-form {
    font-family: Montserrat;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 3%;
  }
  .text-form {
    color: var(--blackbase, #000);
    font-family: Montserrat;
    font-size: px;
    font-style: normal;
    line-height: normal;
    text-align: justify !important;
  }
  .external-text {
    margin-left: 20%;
  }

  .external-des {
    margin-right: 10%;
  }
  .no-external {
    text-align: center !important;
    margin-left: 3%;
  }
}

@media only screen and (max-width: 768px) {
  .title-form {
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .text-form {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;

    margin-left: 4%;
    line-height: normal;
  }

  .img-form {
    display: block;
    margin-right: auto;
    margin-left: auto;
    width: 50%;
  }
}

.modal-vue3-footer-ok {
  display: none !important;
}

@media only screen and (max-width: 456px) {
}
</style>