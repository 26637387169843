import { createServer, Model } from "miragejs"
import { data } from "./data"

export function makeServer({ environment = "development" } = {}) {
  let server = createServer({
    environment,

    models: {
      level: Model,
    },

    seeds(server) {
      server.create("level", {url:"test",form:"https://share.hsforms.com/1FximQlhKQ82KZPIJhpRjXgnx6xs",img:"nickelodeon2.png" })

    },

    routes() {
      this.namespace = "api"

      this.get("/level/:url", (schema,request) => {
        let url = request.params.url;
        let datas = data
       return datas.find(x=>x.url.toUpperCase().includes(url.toUpperCase()));
      })
    },
  })

  return server
}