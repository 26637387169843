<template>
  <div class="container footer-bg">
    <div class="columns footer-aling is-multiline">
      <div class="column is-4">
        <figure class="image " >
          <img
            src="@/assets/img/logos/Group4.png"
         
            style="height: 57px; width: 139px; margin-top: 3%"
          />
        </figure>
      </div>
      <div class="column is-8"></div>
      <div class="column">
        <h3 class="foter-titles">Resorts</h3>
        <ul>
          <li>Nickelodeon Riviera Maya</li>
          <li>Generations Riviera Maya</li>
          <li>El Dorado Casitas</li>
          <li>El Dorado Royale</li>
          <li>Palafitos</li>
          <li>El Dorado Maroma</li>
          <li>El Dorado Seaside</li>
          <li>El Dorado Seaside Palms</li>
          <li>Hidden Beach Au Naturel Resort</li>
        </ul>
      </div>
      <div class="column">
        <h3 class="foter-titles">Benefits Available:</h3>
        <ul>
          <li>Non Presidential</li>
          <li>Sapphire</li>
          <li>Sapphire Elite</li>
          <li>Ruby</li>
          <li>Emerald</li>
          <li>Diamond</li>
          <li>Diamond Elite</li>
        </ul>
      </div>
      <div class="column">
        <h3 class="foter-titles-contact">Contact Us</h3>
        <div class="columns is-multiline">
          <div class="column is-6-desktop is-12-tablet">
            <div class="select">
              <select class="items-bg">
                <option class="option">Reservations</option>
                <option>US and Canada: 855 285 0171</option>
                <option>México: 800 649 0857</option>
              </select>
            </div>
          </div>
          <div class="column is-6-desktop is-12-tablet">
            <div class="select">
              <select class="items-bg">
                <option>Pre call Service</option>
                <option>Toll Free: 855 280 1081</option>
                <option>Nacional MXN: 800 649 085</option>
              </select>
            </div>
          </div>
          <div class="column is-6-desktop is-12-tablet">
            <div class="select">
              <select class="items-bg">
                <option>Rewards Program</option>
                <option>Toll Free US & CAN: 855 282 0620</option>
                <option>Nacional MXN: 800 649 0826</option>
              </select>
            </div>
          </div>
          <div class="column is-6-desktop is-12-tablet">
            <div class="select">
              <select class="items-bg">
                <option>Mails</option>
                <option>atencionsocios@exotictravelers.com</option>
                <option>exoticexchanges@exotictravelers.com</option>
                <option>memberservices@exotictravelers.com</option>
                <option>prearrivalservices@exotictravelers.com</option>
                <option>rewardsprograms@exotictravelers.com</option>
                <option>reservationservices@exotictravelers.com</option>
                <option>contact@exotictravelers.com</option>
                <option>contacto@exotictravelers.com</option>
              </select>
            </div>
          </div>
          
        </div>
      </div>
      <div class="column is-12">
        <p class="police">My Rewards | Member Monthly Deals | Privacy Policy | Reservation Policies | © 2023, Exotic Travelers, All Rights Reserved.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.footer-bg {
  background-color: #f2f2f2;
}
.input,
.textarea,
.select select {
  background-color: black !important;
  border-color: #f2f2f2;
  border-radius: 4px;
  color: white !important;
  
}
.police{
  color: var(--blackbase, #000);
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-align: center;
}

.select:not(.is-multiple):not(.is-loading)::after {
  border-color: white !important;
  right: 1.125em;
  z-index: 4;
}

option :focus {outline:0 !important;}
option :hover {outline:0 !important;}
.foter-titles {
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 2%;
  color: var(--blackbase) !important;
}
.foter-titles-contact {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 2%;
  color: var(--blackbase) !important;
}

@media only screen and (min-width: 1024px) {
  .image-footer{
    margin-right: 10% !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .footer-aling {
    margin-left: 4% !important;
  }
}


@media only screen and (max-width: 768px) {
  .footer-aling {
    margin-left: 4% !important;
  }


}
</style>